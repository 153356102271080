<template>
  <div>
    <v-toolbar flat>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item">
          <b style="color: #1976d2;">Role</b>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="card-header-form">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="params.search"
            placeholder="Search"
          />
          <div class="input-group-btn">
            <button
              class="btn btn-primary"
              @click="fetchListBankUser(params)"
              style="
                border-radius: 0px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
              "
            >
              <i class="fas fa-search" style="border-radius: 0 !important"></i>
            </button>
          </div>
          <button
            class="ml-2 btn btn-danger"
            @click="dialogDelete = !dialogDelete"
            :disabled="dataDelete && dataDelete.length < 1"
            v-if="checkUserPermission('DELETE')"
            style="border-radius: 50px"
          >
            <i class="fas fa-trash" style="border-radius: 0 !important"></i>
          </button>
          
          <button
            class="ml-2 btn btn-primary"
            @click="create"
            v-if="checkUserPermission('CREATE')"
            style="border-radius: 50px"
          >
            <i class="fas fa-plus-circle" style="border-radius: 0 !important"></i>
          </button>
          <dialog-delete
            :dialog-delete="dialogDelete"
            :darkmode="darkmode"
            v-on:closeDelete="closeDelete"
            v-on:deleteItemConfirm="deleteItemConfirm"
          />
          <DialogAction :darkmode="darkmode" />
        </div>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {
    DialogDelete: () =>
      import("../../../components/shared/notify/DialogDelete"),
    DialogAction: () => import("./Dialog.vue"),
  },
  props: ["darkmode", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    async create() {
      await this.fetchPopUpBankUser(true);
      await this.fetchListPermission();
      await this.fetchParentBankUser();
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async deleteItemConfirm() {
      await this.deleteBankUser({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      await this.fetchListBankUser(this.params);
      this.dialogDelete = false;
    },
    ...mapActions("$_bankUser", [
      "fetchPopUpBankUser",
      "fetchListBankUser",
      "deleteBankUser",
      "fetchParentBankUser",
    ]),
    ...mapActions("$_permission", ["fetchListPermission"]),
  },
};
</script>
<style lang="scss" scoped>
.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 14px;
  padding: 5px 15px;
  height: 34px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  height: 36px !important;
}
</style>
